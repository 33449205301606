<template>
  <div class="pagination-style-1" data-aos="fade-up" data-aos-delay="200">
    <ul>
      <li v-for="page in totalPages" :key="page">
        <a
          :class="{ active: currentPage === page }"
          href="#"
          @click.prevent="changePage(page)"
        >
          {{ page }}
        </a>
      </li>
      <li>
        <a class="next" href="#" @click.prevent="changePage(page + 1)">
          <ion-icon :icon="chevronForwardOutline"></ion-icon>
          <ion-icon :icon="chevronForwardOutline"></ion-icon>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { IonIcon } from "@ionic/vue";
import { chevronForwardOutline } from "ionicons/icons";

export default {
  components: { IonIcon },
  props: {
    currentPage: Number,
    totalPages: Number,
  },
  emits: ["changePage"],
  setup(props, { emit }) {
    function changePage(pageNum) {
      if (!pageNum !== props.currentPage && pageNum <= props.totalPages) {
        emit("changePage", pageNum);
      }
    }

    return { changePage, chevronForwardOutline };
  },
};
</script>

<style scoped>
.pagination-style-1 ul {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.pagination-style-1 ul li {
  list-style-type: none;
}
.pagination-style-1 ul li a {
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  border-radius: 100%;
  margin: 0 4px;
  color: #333;
  text-align: center;
}
.pagination-style-1 ul li a:hover {
  color: #e97730;
}
.pagination-style-1 ul li a.active {
  background-color: #e97730;
  color: #ffffff;
}
</style>
