import { createStore } from "vuex";

import productsModule from "./modules/products/index.js";
import cartModule from "./modules/cart/index.js";
import homeModule from "./modules/home/index.js";

const store = createStore({
  state() {
    return {
      isAuth: false,
    }
  },
  getters: {
    isAuth(state) {
      return state.isAuth;
    }
  },
  mutations: {
    auth(state, newVal) {
      state.isAuth = newVal;
    }
  },
  modules: {
    products: productsModule,
    cart: cartModule,
    home: homeModule,
  }
});

export default store;