import { createRouter, createWebHistory } from '@ionic/vue-router';
import TabsPage from '../views/TabsPage.vue'
import store from '../store';

const routes = [
  {
    path: '/',
    redirect: '/tabs/tab1'
  },
  {
    path: '/tabs/',
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/tabs/tab1'
      },
      {
        path: 'tab1',
        component: () => import('@/views/tab1/HomePage.vue')
      },
      {
        path: 'tab2',
        component: () => import('@/views/tab2/ShopPage.vue'),
      },
      {
        path: 'tab2/category/:id',
        component: () => import('@/views/tab2/CategoryPage.vue')
      },
      {
        path: 'tab2/sku/:id',
        component: () => import('@/views/tab2/ProductPage.vue')
      },
      {
        path: 'tab2/search',
        component: () => import('@/views/tab2/SearchPage.vue')
      },
      {
        path: 'tab2/compare',
        component: () => import('@/views/tab2/ComparePage.vue')
      },
      {
        path: 'tab3',
        component: () => import('@/views/tab3/CartPage.vue')
      },
      {
        path: 'tab3/checkout',
        component: () => import('@/views/tab3/CheckoutPage.vue')
      },
      {
        path: 'tab3/thankyou',
        component: () => import('@/views/tab3/ThankYouPage.vue')
      },
      {
        path: 'tab4',
        component: () => import('@/views/tab4/UserPage.vue')
      },
      {
        path: 'tab4/wishlist',
        component: () => import('@/views/tab4/WishlistPage.vue')
      },
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/LoginPage.vue')
  },
  {
    path: '/news',
    component: () => import('@/views/news/NewsList.vue')
  },
  {
    path: '/news/:id',
    component: () => import('@/views/news/NewsItem.vue')
  },
  {
    path: '/pages/delivery',
    component: () => import('@/views/pageBlock/DeliveryPage.vue')
  },
  {
    path: '/pages/about',
    component: () => import('@/views/pageBlock/AboutUsPage.vue')
  },
  {
    path: '/pages/privacy',
    component: () => import('@/views/pageBlock/PrivacyPage.vue')
  },
  {
    path: '/pages/terms',
    component: () => import('@/views/pageBlock/TermsPage.vue')
  },
  {
    path: '/pages/payment',
    component: () => import('@/views/pageBlock/PaymentPage.vue')
  },
  {
    path: '/pages/contact',
    component: () => import('@/views/pageBlock/ContactPage.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (!store.state.isAuth && to.path !== '/login') next({ path: '/login' })
  else next()
})

export default router
