<template>
  <ion-header>
    <div class="header-bottom">
      <div class="container">
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button class="logo" router-link="/tabs/tab1">
              <img src="@/assets/images/logo/logo.png" alt="" />
            </ion-button>
          </ion-buttons>
          <ion-buttons class="ion-justify-content-center main-menu">
            <ion-button router-link="/tabs/tab1">Главная</ion-button>
            <ion-button router-link="/tabs/tab2">Магазин</ion-button>
            <ion-button @click="popoverOpen($event)" id="pages">Страницы</ion-button>
            <ion-popover :is-open="isOpen" :event="event" @didDismiss="isOpen = false">
              <ion-list>
                <ion-item @click="openPage('/pages/about')">О нас</ion-item>
                <ion-item @click="openPage('/tabs/tab3')">Корзина</ion-item>
                <ion-item @click="openPage('/tabs/tab3/checkout')">
                  Оформление заказа
                </ion-item>
                <ion-item @click="openPage('/tabs/tab4')">Мой профиль</ion-item>
                <ion-item @click="openPage('/tabs/tab4/wishlist')">Вишлист</ion-item>
                <ion-item @click="openPage('/tabs/tab2/compare')">
                  Сравнение товаров
                </ion-item>
                <ion-item @click="openPage('/pages/contact')">Связь с нами</ion-item>
                <ion-item @click="openPage('/login')">Логин / Регистрация</ion-item>
              </ion-list>
            </ion-popover>
            <ion-button router-link="/news">Новости</ion-button>
            <ion-button router-link="/pages/about">О нас</ion-button>
            <ion-button router-link="/pages/contact">Связь с нами</ion-button>
          </ion-buttons>
          <ion-buttons slot="end">
            <div class="search-wrap-1" :class="{ open: isSearchOpen }">
              <form action="#">
                <input
                  v-model="searchingText"
                  placeholder="Search products…"
                  type="text"
                />
                <button class="button-search" type="button" @click="openSearchPage()">
                  <ion-icon slot="icon-only" :icon="searchOutline"></ion-icon>
                </button>
              </form>
            </div>
            <ion-button @click="toggleSearchModal()" id="search-popover">
              <ion-icon
                v-if="isSearchOpen"
                slot="icon-only"
                :icon="closeOutline"
              ></ion-icon>
              <ion-icon v-else slot="icon-only" :icon="searchOutline"></ion-icon>
            </ion-button>
            <ion-button router-link="/tabs/tab4">
              <img src="@/assets/icons/user.svg" alt="" />
            </ion-button>
            <ion-button router-link="/tabs/tab4/wishlist">
              <img src="@/assets/icons/like.svg" alt="" />
            </ion-button>
            <ion-button @click="toggleCartModal()">
              <img src="@/assets/icons/shopbag.svg" alt="" />
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </div>
    </div>
  </ion-header>
  <cart-modal :is-open="isCartOpen" @toggleModal="toggleCartModal"></cart-modal>
</template>

<script>
import {
  IonHeader,
  IonToolbar,
  IonButton,
  IonButtons,
  IonPopover,
  IonList,
  IonItem,
  IonIcon,
} from "@ionic/vue";
import CartModal from "@/components/CartModal.vue";
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { searchOutline, closeOutline } from "ionicons/icons";

export default {
  components: {
    IonHeader,
    IonToolbar,
    IonButton,
    IonButtons,
    IonPopover,
    IonList,
    IonItem,
    IonIcon,
    CartModal,
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const isOpen = ref(false);
    const event = ref(null);

    function popoverOpen(e) {
      event.value = e;
      isOpen.value = true;
    }

    const isSearchOpen = ref(false);
    const searchingText = ref("");

    function toggleSearchModal() {
      isSearchOpen.value = !isSearchOpen.value;
    }

    const isCartOpen = ref(false);

    function toggleCartModal() {
      isCartOpen.value = !isCartOpen.value;
    }

    function openSearchPage() {
      if (searchingText.value !== "") {
        isSearchOpen.value = false;
        store.dispatch("products/searchProducts", searchingText.value);
        router.push("/tabs/tab2/search");
      }
    }

    function openPage(pageUrl) {
      event.value = null;
      isOpen.value = false;
      router.push(pageUrl);
    }

    return {
      isOpen,
      event,
      isSearchOpen,
      searchingText,
      popoverOpen,
      openPage,
      openSearchPage,
      toggleSearchModal,
      isCartOpen,
      toggleCartModal,
      searchOutline,
      closeOutline,
    };
  },
};
</script>

<style scoped>
ion-header {
  display: flex;
  justify-content: center;
  background: #fff;
}
.main-menu {
  height: 120px;
}

ion-popover {
  --background: #fff none repeat scroll 0 0;
  --backdrop-opacity: 0;
  --box-shadow: 0 0 20px 0.4px rgba(0, 0, 0, 0.1);
  --color: white;
  --max-height: 636px;
  --width: 140px;
  padding: 25px 15px 25px;
}

ion-popover ion-content::part(scroll) {
  padding: 25px 15px 25px;
  color: #383838;
}

ion-popover::part(backdrop) {
  background-color: rgb(6, 14, 106);
}

.search-wrap-1 {
  background-color: transparent;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  visibility: hidden;
  width: 340px;
  z-index: -99;
  padding-bottom: 1px;
  margin-right: 10px;
}
@media only screen and (max-width: 767px) {
  .search-wrap-1 {
    top: 200%;
    -webkit-transform: inherit;
    -ms-transform: inherit;
    transform: inherit;
    width: 300px;
    left: -154px;
    margin-right: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .search-wrap-1 {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 300px;
    left: inherit;
    margin-right: 10px;
  }
}
.search-wrap-1 form {
  position: relative;
  overflow: hidden;
}
.search-wrap-1 form input {
  background-color: #fff;
  border: 1px solid #e2dcdc;
  color: #000000;
  line-height: 30px;
  padding: 5px 60px 5px 20px;
  width: 100%;
  border-radius: 50px;
}
.search-wrap-1 form input::-moz-input-placeholder {
  color: #000000;
  opacity: 1;
}
.search-wrap-1 form input::-webkit-input-placeholder {
  color: #000000;
  opacity: 1;
}
.search-wrap-1 form button.button-search {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 0;
  border: none;
  background-color: transparent;
  height: 100%;
  font-size: 18px;
  border-left: 1px solid #e2dcdc;
  padding: 0 15px;
  color: #000000;
  line-height: 45px;
}
.search-wrap-1 form button.button-search ion-icon {
  height: 18px;
  width: 18px;
}
.search-wrap-1 form button.button-search:hover {
  color: #e97730;
}
.search-wrap-1.open {
  opacity: 1;
  right: 100%;
  visibility: visible;
  z-index: 99;
}
@media only screen and (max-width: 767px) {
  .search-wrap-1.open {
    right: inherit;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .search-wrap-1.open {
    right: 100%;
  }
}
</style>
