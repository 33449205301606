import httpClient from "../../../api/httpClient";

export default {
  async getCategories(context) {
    try {
      let response = await httpClient.get(`/category/?time=${new Date().toJSON().slice(0, 16)}`);

      context.commit("setCategories", response.data.data.cats);
    } catch(error) {
      console.error(error.toJSON());
    }
  },
  async getCategory(context, payload) {
    try {
      let response = await httpClient.get(`/category/?id=${payload}?time=${new Date().toJSON().slice(0, 16)}`);

      context.commit("setProductsInCategory", response.data.data.skus);
      context.commit("setFilters", response.data.data.filters);
    } catch(error) {
      console.error(error.toJSON());
    }
  },
  async getProduct(context, payload) {
    try {
      let response = await httpClient.get(`/sku/?id=${payload}?time=${new Date().toJSON().slice(0, 16)}`);
      let response2 = await httpClient.get(`/category/?id=${response.data.data.parent}?time=${new Date().toJSON().slice(0, 16)}`);

      context.commit("setProductInfo", response.data.data);
      context.commit("setProductsInCategory", response2.data.data.skus);
    } catch(error) {
      console.error(error.toJSON());
    }
  },
  submitNewReview(context, formData) {
    console.log("form data: ", formData);

    // отправление на сервер данных с формы для отзывов
  },
  searchProducts(context, searchText) {
    console.log("search text: ", searchText);

    // поиск по запросу (API)

    // обновление результатов поиска
    context.commit('updateSearchingResults', context.state.products);
  },
  searchByParams(context, params) {
    console.log("searching params: ", params);

    // поиск продукта по конкретным параметрам (API)

    // обновление результатов поиска
    context.commit('updateSearchingResults', context.state.products);
  }
};