import cartGetters from "./getters";
import cartActions from "./actions";
import cartMutations from "./mutations";

export default {
  namespaced: true,
  state() {
    return {
      products: [
        {
          id: 1,
          name: "Stylish Swing Chair",
          count: 1,
          price: "$49.00",
          imgUrl: ""
        },
        {
          id: 2,
          name: "Modern Chairs",
          count: 1,
          price: "$49.00",
          imgUrl: ""
        },
      ],
      subtotal: "$170.00"
    };
  },
  mutations: cartMutations,
  actions: cartActions,
  getters: cartGetters
}