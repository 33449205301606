import productMutations from "./mutations.js";
import productActions from "./actions.js";
import productGetters from "./getters.js";

export default {
  namespaced: true,
  state() {
    return {
      categories: [],
      productsInCategory: [],
      productInfo: {},
      filters: [],
      catsForFilter: [
        { categoryName: 'Аксессуары ', quantity: 4 },
        { categoryName: 'Для книг ', quantity: 9 },
        { categoryName: 'Для одежды ', quantity: 5 },
        { categoryName: 'Для дома ', quantity: 3 },
        { categoryName: 'Для детей ', quantity: 4 },
        { categoryName: 'Столы и стулья ', quantity: 8 },
        { categoryName: 'Здоровье & Красота', quantity: 3 },
        { categoryName: 'Бытовая техника ', quantity: 4 },
      ],
      colors: [
        { colorName: "Чёрный", quantity: 4, colorClass: "black" },
        { colorName: "Синий", quantity: 9, colorClass: "blue" },
        { colorName: "Коричневый", quantity: 5, colorClass: "brown" },
        { colorName: "Красный", quantity: 3, colorClass: "red" },
        { colorName: "Оранжевый", quantity: 4, colorClass: "orange" },
      ],
      sizes: [
        { productSize: "XL", quantity: 4 },
        { productSize: "M", quantity: 9 },
        { productSize: "LM", quantity: 5 },
        { productSize: "L", quantity: 3 },
        { productSize: "ML", quantity: 4 },
      ],
      tags: ['Всё', 'Для одежды', 'Для книг', 'Аксессуары', 'Столы и стулья', 'Для дома', 'Бытовая техника', 'Одежда', 'Для детей', 'Красота'],
      products: [
        {
          id: 42,
          title: "Interior moderno render",
          price: "55.61",
          regularPrice: "$25.89 ",
          salePrice: "$20.25 ",
          img: "product-5.png",
          discount: "10",
          longDesc:
            "Lorem ipsum dolor sit amet, consectetur adipic it, sed do eiusmod tempor labor incididunt ut et dolore magna aliqua.",
          raiting: 5,
          reviews: [
            {
              id: 1,
              name: "HasTech",
              reviewText:
                "Donec accumsan auctor iaculis. Sed suscipit arcu ligula, at egestas magna molestie a. Proin ac ex maximus, ultrices justo eget, sodales orci. Aliquam egestas libero ac turpis pharetra, in vehicula lacus scelerisque",
              raiting: 4,
              reviewDate: "April 29, 2022"
            }
          ]
        },
        {
          id: 2,
          productName: "Stylish Dining Chair",
          productPrice: "$50.25 ",
          imgUrl: "assets/images/product/product-6.png",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipic it, sed do eiusmod tempor labor incididunt ut et dolore magna aliqua.",
          raiting: 4,
          reviews: [
            {
              id: 1,
              name: "HasTech",
              reviewText:
                "Donec accumsan auctor iaculis. Sed suscipit arcu ligula, at egestas magna molestie a. Proin ac ex maximus, ultrices justo eget, sodales orci. Aliquam egestas libero ac turpis pharetra, in vehicula lacus scelerisque",
              raiting: 4,
              reviewDate: "April 29, 2022"
            }
          ]
        },
        {
          id: 3,
          productName: "Round Standard Chair",
          oldPrice: "$45.00 ",
          newPrice: "$40.00 ",
          imgUrl: "assets/images/product/product-7.png",
          discount: "10",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipic it, sed do eiusmod tempor labor incididunt ut et dolore magna aliqua.",
          raiting: 4,
          reviews: [
            {
              id: 1,
              name: "HasTech",
              reviewText:
                "Donec accumsan auctor iaculis. Sed suscipit arcu ligula, at egestas magna molestie a. Proin ac ex maximus, ultrices justo eget, sodales orci. Aliquam egestas libero ac turpis pharetra, in vehicula lacus scelerisque",
              raiting: 4,
              reviewDate: "April 29, 2022"
            }
          ]
        },
        {
          id: 4,
          productName: "Stylish Swing Chair",
          productPrice: "$30.25 ",
          imgUrl: "assets/images/product/product-4.png",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipic it, sed do eiusmod tempor labor incididunt ut et dolore magna aliqua.",
          raiting: 4,
          reviews: [
            {
              id: 1,
              name: "HasTech",
              reviewText:
                "Donec accumsan auctor iaculis. Sed suscipit arcu ligula, at egestas magna molestie a. Proin ac ex maximus, ultrices justo eget, sodales orci. Aliquam egestas libero ac turpis pharetra, in vehicula lacus scelerisque",
              raiting: 4,
              reviewDate: "April 29, 2022"
            }
          ]
        },
        {
          id: 5,
          productName: "Modern Swivel Chair",
          oldPrice: "$25.89 ",
          newPrice: "$20.25 ",
          imgUrl: "assets/images/product/product-8.png",
          discount: "10",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipic it, sed do eiusmod tempor labor incididunt ut et dolore magna aliqua.",
          raiting: 4,
          reviews: [
            {
              id: 1,
              name: "HasTech",
              reviewText:
                "Donec accumsan auctor iaculis. Sed suscipit arcu ligula, at egestas magna molestie a. Proin ac ex maximus, ultrices justo eget, sodales orci. Aliquam egestas libero ac turpis pharetra, in vehicula lacus scelerisque",
              raiting: 4,
              reviewDate: "April 29, 2022"
            }
          ]
        },
        {
          id: 6,
          productName: "New Modern Sofa Set",
          productPrice: "$50.25 ",
          imgUrl: "assets/images/product/product-2.png",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipic it, sed do eiusmod tempor labor incididunt ut et dolore magna aliqua.",
          raiting: 5,
          reviews: [
            {
              id: 1,
              name: "HasTech",
              reviewText:
                "Donec accumsan auctor iaculis. Sed suscipit arcu ligula, at egestas magna molestie a. Proin ac ex maximus, ultrices justo eget, sodales orci. Aliquam egestas libero ac turpis pharetra, in vehicula lacus scelerisque",
              raiting: 4,
              reviewDate: "April 29, 2022"
            }
          ]
        },
        {
          id: 7,
          productName: "Easy Modern Chair",
          oldPrice: "$45.00 ",
          newPrice: "$40.00 ",
          imgUrl: "assets/images/product/product-3.png",
          discount: "10",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipic it, sed do eiusmod tempor labor incididunt ut et dolore magna aliqua.",
          raiting: 3,
          reviews: [
            {
              id: 1,
              name: "HasTech",
              reviewText:
                "Donec accumsan auctor iaculis. Sed suscipit arcu ligula, at egestas magna molestie a. Proin ac ex maximus, ultrices justo eget, sodales orci. Aliquam egestas libero ac turpis pharetra, in vehicula lacus scelerisque",
              raiting: 4,
              reviewDate: "April 29, 2022"
            }
          ]
        },
        {
          id: 8,
          productName: "Modern Lounge Chairs",
          productPrice: "$30.25 ",
          imgUrl: "assets/images/product/product-9.png",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipic it, sed do eiusmod tempor labor incididunt ut et dolore magna aliqua.",
          raiting: 4,
          reviews: [
            {
              id: 1,
              name: "HasTech",
              reviewText:
                "Donec accumsan auctor iaculis. Sed suscipit arcu ligula, at egestas magna molestie a. Proin ac ex maximus, ultrices justo eget, sodales orci. Aliquam egestas libero ac turpis pharetra, in vehicula lacus scelerisque",
              raiting: 4,
              reviewDate: "April 29, 2022"
            }
          ]
        },
        {
          id: 9,
          productName: "Modern Lounge Chairs",
          oldPrice: "$25.89 ",
          newPrice: "$20.25 ",
          imgUrl: "assets/images/product/product-9.png",
          discount: "10",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipic it, sed do eiusmod tempor labor incididunt ut et dolore magna aliqua.",
          raiting: 4,
          reviews: [
            {
              id: 1,
              name: "HasTech",
              reviewText:
                "Donec accumsan auctor iaculis. Sed suscipit arcu ligula, at egestas magna molestie a. Proin ac ex maximus, ultrices justo eget, sodales orci. Aliquam egestas libero ac turpis pharetra, in vehicula lacus scelerisque",
              raiting: 4,
              reviewDate: "April 29, 2022"
            }
          ]
        },
      ],
      reviews: [
        {
          id: 1,
          name: "HasTech",
          reviewText:
            "Donec accumsan auctor iaculis. Sed suscipit arcu ligula, at egestas magna molestie a. Proin ac ex maximus, ultrices justo eget, sodales orci. Aliquam egestas libero ac turpis pharetra, in vehicula lacus scelerisque",
          raiting: 4,
          reviewDate: "April 29, 2022"
        }
      ],
      searchingResults: []
    };
  },
  mutations: productMutations,
  actions: productActions,
  getters: productGetters
};