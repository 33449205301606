<template>
  <div class="product-wrap mb-35">
    <div class="product-img img-zoom mb-25">
      <router-link :to="routeGoTo">
        <img v-if="imgUrl" :src="imgUrl" alt="" />
        <img v-else src="@/assets/images/product/product-5.png" alt="" />
      </router-link>
      <div v-if="discount" class="product-badge badge-top badge-right badge-pink">
        <span>-{{ discount }}%</span>
      </div>
      <div v-if="!isCategory" class="product-action-wrap">
        <ion-button class="product-action-btn-1" title="Wishlist">
          <ion-icon
            slot="icon-only"
            :icon="heartOutline"
            class="product-action-icon-1"
          ></ion-icon>
        </ion-button>
        <ion-button
          class="product-action-btn-1"
          title="Quick View"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          <ion-icon
            slot="icon-only"
            :icon="eyeOutline"
            class="product-action-icon-1"
          ></ion-icon>
        </ion-button>
      </div>
      <div v-if="!isCategory" class="product-action-2-wrap">
        <ion-button class="product-action-btn-2" title="Add To Cart">
          <ion-icon :icon="cartOutline" class="product-action-icon-2"></ion-icon> Добавить
          в корзину
        </ion-button>
      </div>
    </div>
    <div class="product-content">
      <h3>
        <router-link :to="routeGoTo">{{ productName }}</router-link>
      </h3>
      <div v-if="!isCategory" class="product-price">
        <div v-if="!!productPrice">
          <span>${{ productPrice }}</span>
        </div>
        <div v-else>
          <span class="old-price">${{ oldPrice }} </span>
          <span class="new-price">${{ newPrice }} </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { IonIcon, IonButton } from "@ionic/vue";
import { computed } from "vue";
import { heartOutline, eyeOutline, cartOutline } from "ionicons/icons";
export default {
  components: { IonIcon, IonButton },
  props: {
    productName: String,
    productPrice: String,
    oldPrice: String,
    newPrice: String,
    imgUrl: String,
    discount: Number,
    id: Number,
    isCategory: Boolean,
  },
  setup(props) {
    const routeGoTo = computed(() => {
      const catalogType = props.isCategory ? 'category' : 'sku';
      return `/tabs/tab2/${catalogType}/${props.id}`;
    });
    return { routeGoTo, heartOutline, eyeOutline, cartOutline };
  },
};
</script>

<style scoped>
.product-wrap {
  overflow: hidden;
  position: relative;
}
.product-wrap .product-img {
  overflow: hidden;
  position: relative;
  height: 300px;
}
.product-wrap .product-img a {
  display: block;
}
.product-wrap .product-img a img {
  width: 100%;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}
.product-wrap .product-img .product-badge {
  position: absolute;
  z-index: 5;
}
.product-wrap .product-img .product-badge.badge-top {
  top: 11px;
}
.product-wrap .product-img .product-badge.badge-right {
  right: 11px;
}
.product-wrap .product-img .product-badge.badge-pink {
  color: #fd1748;
}
.product-wrap .product-img .product-badge span {
  font-size: 16px;
  font-weight: 500;
}
.product-wrap .product-img .product-action-wrap {
  display: inline-block;
  left: 0;
  opacity: 1;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  right: 0;
  text-align: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}
.product-wrap .product-img .product-action-wrap .product-action-btn-1 {
  --color: #000;
  background-color: #fff;
  --background: #fff;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: inline-block;
  height: 45px;
  margin: 0 3px;
  --padding-start: 0;
  --padding-end: 0;
  text-align: center;
  width: 45px;
  border: none;
  border-radius: 6px;
  --border-radius: 6px;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  --transition: all 0.4s ease 0s;
}

.product-wrap
  .product-img
  .product-action-wrap
  .product-action-btn-1
  .product-action-icon-1 {
  line-height: 45px;
}
.product-wrap .product-img .product-action-wrap .product-action-btn-1:first-child {
  -webkit-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  transform: translateY(-20px);
}

.product-wrap .product-img .product-action-wrap .product-action-btn-1:last-child {
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
}
.product-wrap .product-img .product-action-wrap .product-action-btn-1:hover {
  color: #ffffff;
  --color: #ffffff;
  background-color: #e97730;
  --background: #e97730;
}
.product-wrap .product-img .product-action-2-wrap {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -20px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}
.product-wrap .product-img .product-action-2-wrap .product-action-btn-2 {
  border: none;
  color: #fff;
  --color: #fff;
  padding: 0;
  --padding-bottom: 0;
  --padding-end: 0;
  --padding-start: 0;
  --padding-top: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 500;
  text-transform: none;
  background-color: #000000;
  --background: #000000;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  --transition: all 0.4s ease 0s;
  width: 100%;
  padding: 9px 10px 10px;
  --padding-top: 9px;
  --padding-start: 10px;
  --padding-end: 10px;
  --padding-bottom: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  --box-shadow: none;
  --background-activated-opacity: 0;
  --background-focused-opacity: 0;
  --background-hover-opacity: 0;
}
.product-wrap
  .product-img
  .product-action-2-wrap
  .product-action-btn-2
  .product-action-icon-2 {
  font-size: 22px;
  margin-right: 5px;
}
.product-wrap .product-img .product-action-2-wrap:hover .product-action-btn-2 {
  background-color: #e97730;
  --background: #e97730;
}
.product-wrap .product-content h3 {
  font-weight: 500;
  margin: 0 0 10px;
  font-size: 16px;
}
.product-wrap .product-content h3 a {
  color: #000000;
  text-decoration: none;
}
.product-wrap .product-content h3 a:hover {
  color: #e97730;
}
.product-wrap:hover .product-img a img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.product-wrap:hover .product-action-wrap {
  opacity: 1;
  visibility: visible;
}
.product-wrap:hover .product-action-wrap .product-action-btn-1:first-child {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}
.product-wrap:hover .product-action-wrap .product-action-btn-1:last-child {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}
.product-wrap:hover .product-action-2-wrap {
  bottom: 0px;
  opacity: 1;
  visibility: visible;
}

.product-price span {
  color: #808080;
  font-size: 18px;
  font-weight: 500;
}
.product-price span.new-price {
  color: #fd1748;
}
.product-price span.old-price {
  margin-right: 13px;
  color: #555252;
  text-decoration: line-through;
}
</style>
