<template>
  <div class="breadcrumb-area bg-gray-4 breadcrumb-padding-1">
    <div class="container">
      <div class="breadcrumb-content ion-text-center">
        <h2 data-aos="fade-up" data-aos-delay="200">{{ pageName }}</h2>
        <ul data-aos="fade-up" data-aos-delay="400">
          <li><router-link to="/tabs/tab1">Главная</router-link></li>
          <li><ion-icon :icon="chevronForwardOutline" class="angle-right"></ion-icon></li>
          <li>{{ pageName }}</li>
        </ul>
      </div>
    </div>
    <div class="breadcrumb-img-1" data-aos="fade-right" data-aos-delay="200">
      <img src="@/assets/images/banner/breadcrumb-1.png" alt="" />
    </div>
    <div class="breadcrumb-img-2" data-aos="fade-left" data-aos-delay="200">
      <img src="@/assets/images/banner/breadcrumb-2.png" alt="" />
    </div>
  </div>
</template>

<script>
import { IonIcon } from "@ionic/vue";
import { chevronForwardOutline } from "ionicons/icons";
export default {
  components: { IonIcon },
  props: {
    pageName: String,
  },
  setup() {
    return { chevronForwardOutline }
  },
}
</script>

<style scoped>
.breadcrumb-area {
  position: relative;
  overflow: hidden;
}
.breadcrumb-area .breadcrumb-img-1 {
  position: absolute;
  left: 0;
  bottom: 0;
}
.breadcrumb-area .breadcrumb-img-1 img {
  max-width: 100%;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .breadcrumb-area .breadcrumb-img-1 img {
    max-width: 280px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .breadcrumb-area .breadcrumb-img-1 img {
    max-width: 280px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-area .breadcrumb-img-1 img {
    max-width: 230px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-area .breadcrumb-img-1 img {
    max-width: 180px;
  }
}
@media only screen and (max-width: 767px) {
  .breadcrumb-area .breadcrumb-img-1 img {
    max-width: 80px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .breadcrumb-area .breadcrumb-img-1 img {
    max-width: 100px;
  }
}
.breadcrumb-area .breadcrumb-img-2 {
  position: absolute;
  right: 0;
  bottom: 0;
}
.breadcrumb-area .breadcrumb-img-2 img {
  max-width: 100%;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .breadcrumb-area .breadcrumb-img-2 img {
    max-width: 280px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .breadcrumb-area .breadcrumb-img-2 img {
    max-width: 280px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-area .breadcrumb-img-2 img {
    max-width: 230px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-area .breadcrumb-img-2 img {
    max-width: 180px;
  }
}
@media only screen and (max-width: 767px) {
  .breadcrumb-area .breadcrumb-img-2 img {
    max-width: 80px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .breadcrumb-area .breadcrumb-img-2 img {
    max-width: 100px;
  }
}
.breadcrumb-area:before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  bottom: -25px;
  margin: 0 auto;
  width: 342px;
  height: 342px;
  border-radius: 100%;
  background-color: #ffffff;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-area:before {
    width: 280px;
    height: 280px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-area:before {
    width: 220px;
    height: 220px;
  }
}
@media only screen and (max-width: 767px) {
  .breadcrumb-area:before {
    width: 180px;
    height: 180px;
  }
}
.breadcrumb-padding-1 {
  padding: 151px 0 152px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-padding-1 {
    padding: 101px 0 102px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-padding-1 {
    padding: 81px 0 82px;
  }
}
@media only screen and (max-width: 767px) {
  .breadcrumb-padding-1 {
    padding: 61px 0 62px;
  }
}
.breadcrumb-content {
  position: relative;
}
.breadcrumb-content h2 {
  font-size: 48px;
  font-weight: 500;
  color: #323232;
  line-height: 1;
  margin: 0 0 29px;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .breadcrumb-content h2 {
    font-size: 40px;
    margin: 0 0 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-content h2 {
    font-size: 40px;
    margin: 0 0 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-content h2 {
    font-size: 30px;
    margin: 0 0 15px;
  }
}
@media only screen and (max-width: 767px) {
  .breadcrumb-content h2 {
    font-size: 25px;
    margin: 0 0 10px;
  }
}
.breadcrumb-content ul {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0;
}
.breadcrumb-content ul li {
  margin-right: 7px;
  color: #686868;
  list-style-type: none;
}
.breadcrumb-content ul li:last-child {
  margin-right: 0;
}
.breadcrumb-content ul li a {
  font-weight: 500;
  font-size: 13px;
  text-decoration: none;
  display: inline-block;
  color: #000000;
}
.breadcrumb-content ul li .angle-right {
  font-size: 13px;
  line-height: 1;
  position: relative;
  top: 2px;
  color: #000000;
}
</style>