import httpClient from "../../../api/httpClient";

export default {
  namespaced: true,
  state() {
    return {
      slides: [],
      cats: [],
      skus: []
    }
  },
  mutations: {
    setSlides(state, payload) {
      state.slides = payload;
    },
    setCats(state, payload) {
      state.cats = payload;
    },
    setSkus(state, payload) {
      state.skus = payload;
    }
  },
  actions: {
    async getData(context) {
      try {
        let response = await httpClient.get(`/home/?time=${new Date().toJSON().slice(0, 16)}`);

        context.commit("setSlides", response.data.data.slides);
        context.commit("setCats", response.data.data.cats);
        context.commit("setSkus", response.data.data.skus);
      } catch(error) {
        console.error(error.toJSON());
      }
    }
  },
  getters: {
    slides(state) {
      return state.slides;
    },
    categories(state) {
      return state.cats;
    },
    products(state) {
      return state.skus;
    }
  }
}