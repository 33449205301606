<template>
  <div class="price-filter">
    <div class="slider-range">
      <ion-range
        :dual-knobs="true"
        :min="props.minValue"
        :max="props.maxValue"
        v-model="rangeValue"
        @ion-change="changeValue"
      ></ion-range>
      <div class="label-input">
        <label>{{ props.filterName }}: ${{ rangeValue.lower }} - ${{ rangeValue.upper }}</label>
        <ion-button
          type="button"
          class="range-btn"
          @click="filter"
        >
          Отфильтровать
        </ion-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { IonRange, IonButton } from "@ionic/vue";
import { ref } from "vue";

const props = defineProps({
  filterName: String,
  filterId: String,
  minValue: String,
  maxValue: String,
});
const emit = defineEmits(['changeRange', 'searchByParams']);

const rangeValue = ref({ lower: +props.minValue, upper: +props.maxValue });

function filter() {
  emit('searchByParams', {id: props.filterId, value: rangeValue.value});
}

function changeValue() {
  emit('changeRange', { id: props.filterId, value: rangeValue.value });
}
</script>

<style scoped>
.sidebar-widget .price-filter .label-input {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidebar-widget .price-filter .label-input label {
  font-size: 14px;
  color: #575757;
  font-family: "Poppins", sans-serif;
  margin-right: 2px;
}
.sidebar-widget .price-filter ion-button {
  background-color: transparent;
  --background: transparent;
  border: medium none;
  --border-style: medium none;
  --box-shadow: none;
  color: #575757;
  --color: #575757;
  line-height: 1;
  padding: 0;
  --padding-start: 0;
  --padding-end: 0;
  cursor: pointer;
  font-size: 14px;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  --transition: all 0.3s ease 0s;
  font-family: "Poppins", sans-serif;
  text-transform: none;
}
.sidebar-widget .price-filter ion-button:hover {
  color: #ed3503;
  --color: #ed3503;
}

ion-range {
  --bar-background: #e5e5e5;
  --bar-background-active: #b7b7b7;
  --knob-background: #bbbbbb;
  --knob-box-shadow: none;
}
</style>
