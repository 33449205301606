export default {
  categories(state) {
    return state.categories;
  },
  catsForFilter(state) {
    return state.catsForFilter;
  },
  product(state) {
    return state.productInfo;
  },
  filters(state) {
    return state.filters;
  },
  colors(state) {
    return state.colors;
  },
  sizes(state) {
    return state.sizes;
  },
  tags(state) {
    return state.tags;
  },
  productsInCategory(state) {
    return state.productsInCategory;
  },
  searchingResults(state) {
    return state.searchingResults;
  },
  reviews(state) {
    return state.reviews;
  }
};