<template>
  <div class="product-details-action-wrap">
    <div class="product-quality">
      <ion-icon
        :icon="removeOutline"
        slot="start"
        @click="changeInputValue(-1)"
      ></ion-icon>
      <input class="input-text qty text" name="qtybutton" :value="inputValue" />
      <ion-icon :icon="addOutline" slot="end" @click="changeInputValue(1)"></ion-icon>
    </div>
    <div class="single-product-cart btn-hover">
      <router-link to="#">Добавить в корзину</router-link>
    </div>
    <div class="single-product-wishlist">
      <router-link to="/tabs/tab4/wishlist" title="Wishlist">
        <ion-icon :icon="heartOutline"></ion-icon>
      </router-link>
    </div>
    <div class="single-product-compare">
      <router-link to="/tabs/tab2/compare" title="Compare">
        <ion-icon :icon="shuffleOutline"></ion-icon>
      </router-link>
    </div>
  </div>
</template>

<script>
import { IonIcon } from "@ionic/vue";
import { ref } from "vue";
import { heartOutline, shuffleOutline, addOutline, removeOutline } from "ionicons/icons";

export default {
  components: { IonIcon },
  setup() {
    const inputValue = ref(1);

    function changeInputValue(changeNum) {
      if (inputValue.value + changeNum !== 0) {
        inputValue.value += changeNum;
      }
    }

    return {
      inputValue,
      changeInputValue,
      heartOutline,
      shuffleOutline,
      addOutline,
      removeOutline,
    };
  },
};
</script>

<style scoped>
.product-details-action-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.product-quality {
  width: 80px;
  overflow: hidden;
  position: relative;
  border: 1px solid #e8e8e8;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.product-quality > input {
  width: 30px;
  border: none;
  height: 60px;
  line-height: 50px;
  text-align: center;
  font-size: 14px;
  color: #8f8f8f;
  padding: 0;
  font-family: "Poppins", sans-serif;
}
.product-quality > input::-moz-input-placeholder {
  color: #8f8f8f;
  opacity: 1;
}
.product-quality > input::-webkit-input-placeholder {
  color: #8f8f8f;
  opacity: 1;
}
.product-quality .qtybutton {
  position: absolute;
  color: #8f8f8f;
  left: 10px;
  cursor: pointer;
}
.product-quality .qtybutton.inc {
  left: auto;
  right: 10px;
  font-size: 20px;
  top: 15px;
}
.product-quality .qtybutton.dec {
  top: -2px;
  font-size: 30px;
  line-height: 60px;
}
.single-product-cart {
  margin: 0 24px 0 10px;
}
.single-product-cart.mrg-none {
  margin: 0 24px 0 0px;
}
@media only screen and (max-width: 767px) {
  .single-product-cart {
    margin: 0 10px 0 10px;
  }
}
.single-product-cart > a {
  display: inline-block;
  font-weight: 600;
  color: #ffffff;
  background-color: #e97730;
  padding: 20px 37px;
  z-index: 1;
  text-decoration: none;
}
@media only screen and (max-width: 767px) {
  .single-product-cart > a {
    padding: 20px 17px;
  }
}
.single-product-wishlist {
  margin-right: 23px;
}
.single-product-wishlist a,
.single-product-compare a {
  font-size: 18px;
  color: #010101;
}
.single-product-wishlist a:hover,
.single-product-compare a:hover {
  color: #e97730;
}
</style>
