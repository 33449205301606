<template>
  <ion-modal
    :is-open="isOpen"
    class="ion-justify-content-end"
    :enter-animation="enterAnimation"
    :leave-animation="leaveAnimation"
  >
    <div class="sidebar-cart-active" :class="{ inside: isOpen }">
      <div class="sidebar-cart-all">
        <a class="cart-close" href="#" @click.prevent="closeModal()">
          <ion-icon :icon="closeOutline"></ion-icon>
        </a>
        <div class="cart-content">
          <ion-text>
            <h3>Shopping Cart</h3>
          </ion-text>
          <ul>
            <li v-for="product in productsInCart" :key="product.id">
              <div class="cart-img">
                <a href="#"><img src="@/assets/images/cart/cart-1.jpg" alt="" /></a>
              </div>
              <div class="cart-title">
                <h4>
                  <a href="#">{{ product.name }}</a>
                </h4>
                <span> {{ product.count }} × {{ product.price }} </span>
              </div>
              <div class="cart-delete">
                <a href="#" @click.prevent="deleteItem(product.id)">×</a>
              </div>
            </li>
          </ul>
          <div class="cart-total">
            <ion-text>
              <h4>
                Subtotal: <span>{{ subtotal }}</span>
              </h4>
            </ion-text>
          </div>
          <div class="cart-btn btn-hover">
            <a @click.prevent="openPage('/tabs/tab3')" class="theme-color">view cart</a>
          </div>
          <div class="checkout-btn btn-hover">
            <a @click.prevent="openPage('/tabs/tab3/checkout')" class="theme-color">
              checkout
            </a>
          </div>
        </div>
      </div>
    </div>
  </ion-modal>
</template>

<script>
import { createAnimation, IonIcon, IonModal, IonText } from "@ionic/vue";
import { useRouter } from "vue-router";
import { computed } from "vue";
import { useStore } from "vuex";
import { closeOutline } from "ionicons/icons";

export default {
  components: { IonIcon, IonModal, IonText },
  props: {
    isOpen: Boolean,
  },
  emits: ["toggleModal"],
  setup(props, { emit }) {
    const router = useRouter();
    const store = useStore();

    function deleteItem(id) {
      store.commit("cart/deleteProduct", id);
    }

    function closeModal() {
      emit("toggleModal");
    }

    function openPage(pageUrl) {
      emit("toggleModal");
      router.push(pageUrl);
    }

    const enterAnimation = (baseEl) => {
      const root = baseEl.shadowRoot;

      const backdropAnimation = createAnimation()
        .addElement(root.querySelector("ion-backdrop"))
        .fromTo("opacity", "0", "var(--backdrop-opacity)");

      const wrapperAnimation = createAnimation()
        .addElement(root.querySelector(".modal-wrapper"))
        .keyframes([
          { offset: 0, opacity: "0", transform: "translate(370px, 0)" },
          { offset: 1, opacity: "0.99", transform: "translate(0, 0)" },
        ]);

      return createAnimation()
        .addElement(baseEl)
        .easing("ease-out")
        .duration(400)
        .addAnimation([backdropAnimation, wrapperAnimation]);
    };

    const leaveAnimation = (baseEl) => {
      return enterAnimation(baseEl).direction("reverse");
    };

    return {
      productsInCart: computed(() => store.getters["cart/productsInCart"]),
      subtotal: computed(() => store.getters["cart/subtotal"]),
      deleteItem,
      closeModal,
      openPage,
      enterAnimation,
      leaveAnimation,
      closeOutline,
    };
  },
};
</script>

<style scoped>
ion-modal {
  --backdrop-opacity: 0.7;
  --height: 100%;
  --width: 420px;
}

.sidebar-cart-active {
  position: fixed;
  top: 0;
  width: 420px;
  padding: 104px 50px 50px;
  min-height: 100vh;
  bottom: 0;
  z-index: 9999;
  right: 0;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 250ms ease-in;
  -o-transition: all 250ms ease-in;
  transition: all 250ms ease-in;
  -webkit-transform: translate(200px, 0);
  -ms-transform: translate(200px, 0);
  transform: translate(200px, 0);
  -webkit-box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
  box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
  background-color: #fff;
}

.sidebar-cart-active.inside {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-cart-active {
    width: 350px;
    padding: 70px 30px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-cart-active {
    padding: 60px 15px 50px;
    width: 300px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .sidebar-cart-active {
    padding: 60px 30px 50px;
    width: 340px;
  }
}

.sidebar-cart-active .sidebar-cart-all .cart-close {
  position: absolute;
  top: 37px;
  right: 50px;
  font-size: 50px;
  line-height: 30px;
  color: #6d6d6d;
  -webkit-transition: all 250ms ease-out;
  -o-transition: all 250ms ease-out;
  transition: all 250ms ease-out;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-cart-active .sidebar-cart-all .cart-close {
    font-size: 40px;
    right: 19px;
    top: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-cart-active .sidebar-cart-all .cart-close {
    top: 27px;
    right: 17px;
    font-size: 30px;
  }
}

.sidebar-cart-active .sidebar-cart-all .cart-close:hover {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  color: #e97730;
}

.sidebar-cart-active .sidebar-cart-all .cart-content > ion-text > h3 {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 35px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul {
  margin: 0 -30px 0 0;
  padding: 0 30px 0 0;
  max-height: 310px;
  max-height: calc(100vh - 430px);
  overflow: auto;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-cart-active .sidebar-cart-all .cart-content ul {
    max-height: calc(100vh - 365px);
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-cart-active .sidebar-cart-all .cart-content ul {
    max-height: calc(100vh - 360px);
  }
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-img {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 70px;
  -ms-flex: 0 0 70px;
  flex: 0 0 70px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-img a img {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title {
  margin-left: 20px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title h4 {
  font-size: 15px;
  margin: 0 0 8px;
  font-weight: 500;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title h4 a {
  color: #000000;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title h4 a:hover {
  color: #e97730;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-title span {
  font-size: 15px;
  color: #000000;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-delete {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 100;
  -webkit-flex-grow: 100;
  -ms-flex-positive: 100;
  flex-grow: 100;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-delete a {
  display: block;
  font-size: 20px;
  height: 20px;
  line-height: 17px;
  overflow: hidden;
  width: 20px;
  text-align: right;
  color: #000000;
}

.sidebar-cart-active .sidebar-cart-all .cart-content ul li .cart-delete a:hover {
  color: #e97730;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-total {
  display: block;
  width: 100%;
  margin: 16px 0 26px;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-total h4 {
  font-size: 16px;
  margin: 0;
  font-weight: 600;
  color: #000000;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-cart-active .sidebar-cart-all .cart-content .cart-total h4 {
    font-size: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-cart-active .sidebar-cart-all .cart-content .cart-total h4 {
    font-size: 15px;
  }
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-total h4 span {
  font-size: 15px;
  color: #000000;
  float: right;
  font-weight: 500;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-btn,
.sidebar-cart-active .sidebar-cart-all .cart-content .checkout-btn {
  display: block;
  width: 100%;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-btn a,
.sidebar-cart-active .sidebar-cart-all .cart-content .checkout-btn a {
  width: 100%;
  display: block;
  text-align: center;
  padding: 18px 20px 17px;
  background-color: #000000;
  color: #fff;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  z-index: 1;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-btn a:first-child,
.sidebar-cart-active .sidebar-cart-all .cart-content .checkout-btn a:first-child {
  margin-top: 0;
}

.sidebar-cart-active .sidebar-cart-all .cart-content .cart-btn {
  margin-bottom: 10px;
}
</style>
