import { createApp } from 'vue'
import App from './App.vue'
import BaseHeader from './components/base/BaseHeader.vue'
import BaseFooter from './components/base/BaseFooter.vue'
import BaseBreadcrumb from './components/base/BaseBreadcrumb.vue'
import BaseCard from './components/base/BaseCard.vue'
import BaseBlog from './components/base/BaseBlog.vue'
import BasePagination from './components/base/BasePagination.vue'
import BaseActionsButtons from './components/base/BaseActionsButtons.vue'
import BaseFunfact from './components/base/BaseFunfact.vue'
import BaseRangeInput from './components/base/BaseRangeInput.vue'

import router from './router';
import store from './store';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* animate.css */
import "@/assets/plugins/animate.css";

const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(store);

app
  .component('base-header', BaseHeader)
  .component('base-footer', BaseFooter)
  .component('base-breadcrumb', BaseBreadcrumb)
  .component('base-card', BaseCard)
  .component('base-blog', BaseBlog)
  .component('base-pagination', BasePagination)
  .component('base-actions-buttons', BaseActionsButtons)
  .component('base-funfact', BaseFunfact)
  .component('base-range-input', BaseRangeInput);
  
router.isReady().then(() => {
  app.mount('#app');
});