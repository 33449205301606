export default {
  setCategories(state, payload) {
    state.categories = payload;
  },
  setProductsInCategory(state, payload) {
    state.productsInCategory = payload;
  },
  setProductInfo(state, payload) {
    state.productInfo = payload;
  },
  setFilters(state, payload) {
    state.filters = payload;
  },
  updateSearchingResults(state, newValue) {
    state.searchingResults = newValue;
  }
};